.home {
  margin: 0 15vw;

  @media screen and (orientation: portrait) {
    margin: 0 auto;
  }

  .title-container {
    font-family: "GunarDemiBold";
    text-transform: uppercase;
    margin: 2vw 0;

    @media screen and (orientation: portrait) {
      background: #e8e8e8;
      width: 100%;
      margin: 4vw 0 8vw 0;
    }

    > .title {
      font-size: 2.5vw;
      padding: 1vw;

      @media screen and (orientation: portrait) {
        font-size: 5.5vw;
      }

      > img {
        vertical-align: middle;
        width: 3.5vw;
        height: 3.5vw;

        @media screen and (orientation: portrait) {
          width: 7vw;
          height: 7vw;
        }
      }

      > span {
        margin-left: 1vw;

        @media screen and (orientation: portrait) {
          margin-left: 2vw;
        }
      }
    }

    > .sub-title {
      font-size: 1vw;
      border-left: 3px solid #ffe500;
      margin-left: 5vw;
      padding: .5vw;
      background: #ddd;
      width: 25%;

      @media screen and (orientation: portrait) {
        background: none;
        font-size: 3vw;
        margin-left: 9vw;
        padding: 1vw;
        width: 100%;
      }
    }
  }

  .nav-tabs {
    display: flex;
    align-items: center;
    margin-bottom: 2vw;

    @media screen and (orientation: portrait) {
      justify-content: space-between;
      margin-bottom: 6vw;
    }

    .tabs-item {
      background: #252525;
      color: #fff;
      text-align: center;
      padding: 1vw 3vw;
      font-size: 1vw;
      cursor: pointer;

      @media screen and (orientation: portrait) {
        flex-basis: 49%;
        padding: 2.5vw;
        font-size: 2vw;
      }

      &.active {
        color: #252525;
        background: url("../../img/button/Button_Active.jpg") no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  .slider-wrapper {
    width: 66vw;
    margin-bottom: 6vw;

    @media screen and (orientation: portrait) {
      width: 80vw;
    }

    .slick-arrow.slick-disabled {
      display: none !important;
    }

    .slick-slide {
      padding-right: 1vw;
    }

    .slider-items {
      position: relative;
      font-weight: bold;

      &:hover {
        .overlay {
          opacity: 1;
        }
      }

      .slide-img {
        width: 100%;
        height: auto;
      }

      .link-mobile {
        display: none;
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
        font-size: 2.5vw;
        background: #ffe500;
        padding: 1vw 0;
        color: #252525;

        @media screen and (orientation: portrait) {
          display: block;
        }
      }

      .overlay {
        position: absolute;
        background: rgba($color: #000000, $alpha: .5);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        transition: .2s all ease-in-out;

        @media screen and (orientation: portrait) {
          display: none;
        }

        .link-desktop {
          position: absolute;
          bottom: 0;
          background: #ffe500;
          width: 80%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          color: #252525;
          padding: .3vw 0;

          &:hover {
            background: #a19100;
          }
        }
      }
    }

    .slick-arrow {
      height: 12vh;
      width: 6vh;
      z-index: 1;

      @media screen and (orientation: portrait) {
        height: 7vh;
        width: 3.5vh;
      }

      &.slick-prev {
        background: url("../../img/button/Button_prev.png") no-repeat;
        background-size: 100% 100%;
        left: -5vw;

        @media screen and (orientation: portrait) {
          left: -8vw;
        }
      }

      &.slick-next {
        background: url("../../img/button/Button_next.png") no-repeat;
        background-size: 100% 100%;
        right: -5vw;

        @media screen and (orientation: portrait) {
          right: -8vw;
        }
      }

      &::after, &::before {
        content: '';
      }
    }
  }
}