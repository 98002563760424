/*
* Fonts
*/

@font-face {
    font-family: "GunarDemiBold";
    src: url("../../fonts/Gunar\ DemiBold.ttf");
}

@font-face {
    font-family: "GunarMedium";
    src: url("../../fonts/Gunar\ Medium.ttf");
}