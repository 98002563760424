.navbar {
  width: 100%;
  background: #000;
  top: 0;
  border-bottom: 1px solid #ffe500;
  color: #ffe500;
  z-index: 10;
  font-family: "GunarDemiBold";

  .desktop-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65%;
    margin: 0 auto;
    font-size: 1.2vw;

    @media screen and (orientation: portrait) {
      display: none;
    }

    .nav-logo {
      margin-right: 2%;
      flex-basis: 14%;
      cursor: pointer;
    }

    ul.nav-item {
      flex-basis: 85%;
      display: flex;
      align-items: center;
      height: 100%;

      li {
        text-align: center;
        flex-basis: 20%;

        &:hover {
          background: url("../../img/button/Hover.png") no-repeat;
          background-size: 100% 100%;

          a {
            color: #252525;
          }

          &.dropdown {
            > ul.dropdown-item {
              display: block;
              background: rgba($color: #000000, $alpha: .75);
            }
          }
        }

        a {
          display: block;
          color: #fff;
          cursor: pointer;
          padding: 12% 0;
        }

        &.dropdown {
          display: block;
          position: relative;

          > ul.dropdown-item {
            display: none;
            position: absolute;
            top: 102%;
            width: 100%;
            height: auto;

            li {
              &:hover {
                background: rgba($color: #000000, $alpha: .9);

                a {
                  color: #ffe500;
                }
              }

              a {
                display: block; 
                padding: 7%;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  .mobile-nav {
    display: none;
    width: 100%;

    @media screen and (orientation: portrait) {
      display: block;
    }

    .main-nav {
      background: #000;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 7vw;

      .hamburger {
        background: transparent;
        display: inline-block;
        margin: 0;
        padding: 0;
        cursor: pointer;

        > .hamburger-box {
          top: 2px;
          width: 10vw !important;

          > .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
            width: 100% !important;
            background-color: #fff !important;
          }
        }
      }

      .nav-logo {
        width: 35vw;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        > img {
          width: 100%;
        }
      }
    }

    .overlay-menu {
      position: absolute;
      width: 100%;
      height: 100vh;
      visibility: hidden;

      &.active {
        background: rgba($color: #000000, $alpha: .5);
        visibility: initial;
      }

      .menu {
        position: absolute;
        left: -100%;
        width: 70%;
        background: #000;
        padding: 8% 8% 0;
        color: #fff;
        height: 100%;
        overflow-y: auto;
        border-top: 1px solid #ffe500;
        transition: all .2s ease-in-out;
        z-index: 99;

        &.active {
          left: 0;
        }

        ul.menu-item {
          margin-bottom: 3vw;

          > li {
            margin-bottom: 7%;
            font-size: 4vw;
            text-transform: uppercase;

            &.collapse {
              position: relative;

              > span {
                display: block;
                position: relative;

                &::after {
                  content: "";
                  background: url("../../img/button/Arrow_dropdown.png") no-repeat top;
                  background-size: contain;
                  width: 4.5vw;
                  height: 4.5vw;
                  position: absolute;
                  top: 50%;
                  right: 0;
                  transform: translateY(-50%);
                  transition: all .3s ease-in-out;
                }
              }

              > .collapse-content {
                overflow: hidden;

                .collapse-menu {
                  margin-top: -150%;
                  margin-bottom: 1vw;
                  transition: all .5s;

                  > li {
                    font-size: 4vw;
                    background: #272727;
                    text-transform: capitalize;
                    margin-bottom: 1vw;

                    > a {
                      display: block;
                      padding: 3% 8%;
                      color: #fff;
                      font-family: "GunarMedium";
                    }
                  }
                }
              }

              &.open {
                > span::after {
                  transform: translateY(-50%) rotate(90deg);
                }

                > .collapse-content {
                  display: block;

                  .collapse-menu {
                    margin-top: 10%;
                  }
                }
              }
            }

            > a {
              display: block;
              cursor: pointer;
              color: #fff;
            }
          }
        }
      }
    }
  }
}